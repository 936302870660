import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { NamedLink } from '../../components';
import { ReactComponent as SearchIcon } from './images/searchIcon.svg';
import { ReactComponent as BloombergIcon } from './images/New_Bloomberg_Logo.svg';
import { ReactComponent as BbcIcon } from './images/BBC_Logo_2021.svg';
import { ReactComponent as UsaTodayIcon } from './images/USA_Today_logo_PNG1.svg';
import { ReactComponent as CnnIcon } from './images/CNN-logo-500x281.svg';
import { ReactComponent as ForbesIcon } from './images/Forbes_logo.svg';
import { ReactComponent as YahooIcon } from './images/Yahoo-logo-500x281.svg';
import { ReactComponent as MsnIcon } from './images/2015_MSN_logo.svg.svg';
import { ReactComponent as BusinessInsiderIcon } from './images/Business_Insider_Logo.svg';
import css from './MarketplaceIntroduction.module.css';
import LocationAutocompleteInputImpl from '../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createResourceLocatorString, matchPathname, pathByRouteName } from '../../util/routes';

const { LatLngBounds } = sdkTypes;
const MarketplaceIntroduction = () => {
  const history = useHistory();

  const handleCtaClick = () => {
    history.push('/s');
  };

  const roundCoordinate = coordinate => {
    return Math.round(coordinate * 1000000) / 1000000; // Rounds to 6 decimal places
  };

  const customOnChange = location => {
    if (location && location.selectedPlace) {
      const { address, bounds } = location.selectedPlace;

      // Use LatLngBounds for handling bounds
      const { ne, sw } = new LatLngBounds(bounds.ne, bounds.sw);

      // Round the coordinates for the URL
      const neLat = roundCoordinate(ne.lat);
      const neLng = roundCoordinate(ne.lng);
      const swLat = roundCoordinate(sw.lat);
      const swLng = roundCoordinate(sw.lng);

      const encodedAddress = encodeURIComponent(address);
      const encodedBounds = encodeURIComponent(`${neLat},${neLng},${swLat},${swLng}`);

      history.push(`/s?address=${encodedAddress}&bounds=${encodedBounds}`);
    }
  };

  const onSubmit = values => {
    if (values.location && values.location.selectedPlace) {
      const { address, bounds } = values.location.selectedPlace;

      // Use LatLngBounds to construct the bounds
      const { ne, sw } = new LatLngBounds(bounds.ne, bounds.sw);

      const encodedAddress = encodeURIComponent(address);
      const encodedBounds = bounds ? `${ne.lat},${ne.lng},${sw.lat},${sw.lng}` : '';

      history.push(`/s?address=${encodedAddress}&bounds=${encodedBounds}`);
    }
  };

  return (
    <div className={css.backdrop}>
      <div className={css.backdropImage}>
        <div className={css.hero}>
          <div className={css.heroSections}>
            <div className={css.heroSubSection}>
              <div className={css.heroInfo}>
                <p className={css.text}>
                  Connecting <br className={css.breakText} />
                  Food and Influence
                </p>
                <h1 className={css.title}>
                  The Place Where <br className={css.titleBreak} /> Restaurants and{' '}
                  <br className={css.titleBreak2} /> Food Influencers Meet
                </h1>
                {/* <div className={css.searchContainer}>
                  <input type="text" className={css.searchInput} placeholder="Search" />
                  <SearchIcon className={css.searchIcon} />
                </div> */}

                <FinalForm
                  onSubmit={onSubmit}
                  className={css.form}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={css.form}>
                      <Field
                        name="location"
                        render={({ input, meta }) => {
                          const { onChange, ...restInput } = input;
                          const searchOnChange = value => {
                            onChange(value);
                            customOnChange(value);
                          };

                          return (
                            <LocationAutocompleteInputImpl
                              // rootClassName={css.rootClassName}
                              // className={css.desktopInputRoot} // This class applies to the root container of the input (sets general layout and size)
                              iconClassName={css.desktopIcon}
                              inputClassName={css.desktopInput}
                              predictionsClassName={css.desktopPredictions}
                              predictionsAttributionClassName={css.desktopPredictionsAttribution}
                              placeholder="Search by city" // Placeholder text in the input
                              closeOnBlur={true}
                              input={{ ...restInput, onChange: searchOnChange }}
                              meta={meta}
                            />
                          );
                        }}
                      />
                    </form>
                  )}
                />

                <div className={css.buttons}>
                  <button className={css.primaryButton} onClick={handleCtaClick}>
                    Find Your Influencer
                  </button>
                  <NamedLink name="SignupPage" className={css.ctaLink}>
                    Join as an Influencer
                  </NamedLink>
                </div>
              </div>
            </div>
            <div className={css.heroContent}>
              <img src="static/images/hamburger.png" alt="Hamburger" className={css.hamburger} />
              <img src="static/images/camera.png" alt="Camera" className={css.camera} />
              <img src="static/images/bag.png" alt="Bag" className={css.bag} />
              <img src="static/images/Hero_Image.jpeg" alt="Hero" className={css.heroImage} />
            </div>
          </div>
        </div>

        <div className={css.heroFooterDesktop}>
          <p className={css.footerText}>AS SEEN ON:</p>
          <div className={css.footer}>
            <img src="static/images/featherLeft.png" alt="feather" className={css.featherLeft} />
            <BloombergIcon />
            <BbcIcon />
            <UsaTodayIcon />
            <CnnIcon />
            <ForbesIcon />
            <YahooIcon />
            <MsnIcon />
            <BusinessInsiderIcon />
            <img src="static/images/featherRight2.png" alt="feather" className={css.featherRight} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceIntroduction;
