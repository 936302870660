import React from 'react';

import css from './RestaurantsSection.module.css';
import { NamedRedirect, NamedLink } from '../../components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CustomersIcon } from './images/customers.svg';
import { ReactComponent as TargetIcon } from './images/target.svg';
import { ReactComponent as ConnectionsIcon } from './images/connections.svg';
import { ReactComponent as ResultsIcon } from './images/results.svg';

const restaurantsCards = [
  {
    title: 'Get More Customers',
    icon: <CustomersIcon />,
    description: 'Team up to attract diners and turn restaurants into local hotspots.',
  },
  {
    title: 'Simple & Effective',
    icon: <TargetIcon />,
    description: 'Easy to connect and manage campaigns in one place.',
  },
  {
    title: 'Trusted Connections',
    icon: <ConnectionsIcon />,
    description: 'Partner with influencers you can rely on to get more market reach.',
  },
  {
    title: 'Real Results',
    icon: <ResultsIcon />,
    description: 'See increased visibility and foot traffic and higher engagement.',
  },
];

const RestaurantsSection = props => {
  const history = useHistory();

  const handleCtaClick = () => {
    history.push('/s');
  };

  return (
    <div className={css.backdrop}>
      <div className={css.content}>
        <p className={css.titleParagraph}>
          Do you own a restaurant? <span>🍽👨‍🍳</span>{' '}
        </p>
        <h1 className={css.title}>TasteTribe for Restaurants</h1>
        <div className={css.cardContainer}>
          {restaurantsCards.map((card, index) => {
            return (
              <div key={index} className={css.card}>
                <div className={css.cardIcon}>{card.icon}</div>
                <h3 className={css.cardTitle}>{card.title}</h3>
                <p className={css.cardDescription}>{card.description}</p>
              </div>
            );
          })}
        </div>
        <button className={css.primaryButton} onClick={handleCtaClick}>
          Get Started
        </button>
      </div>
      <img src="static/images/dish.png" alt="Dish Image" className={css.dish} />
    </div>
  );
};

RestaurantsSection.defaultProps = {};

RestaurantsSection.propTypes = {};

export default RestaurantsSection;
