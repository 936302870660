import React from 'react';

import css from './InfluencersSection.module.css';
import { NamedRedirect, NamedLink } from '../../components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EarnIcon } from './images/EarnIcon.svg';
import { ReactComponent as TrustIcon } from './images/TrustIcon.svg';
import { ReactComponent as ContentIcon } from './images/ContentIcon.svg';
import { ReactComponent as StreamlinedIcon } from './images/StreamlinedIcon.svg';

const restaurantsCards = [
  {
    title: 'Earn More',
    icon: <EarnIcon />,
    description:
      'Get paid for promoting restaurants you love, with secure payments and clear terms.',
  },
  {
    title: 'Build Trust',
    icon: <TrustIcon />,
    description:
      'Collaborate with reputable restaurants to enhance your profile and gain trust with your audience.',
  },
  {
    title: 'Create More Content',
    icon: <ContentIcon />,
    description:
      'Expand your content library by working with a variety of dining establishments, showcasing diverse culinary stories.',
  },
  {
    title: 'Simple and Streamlined',
    icon: <StreamlinedIcon />,
    description:
      'Manage all your campaigns, bookings, and payments in one place, making your work easier and more efficient.',
  },
];

const InfluencersSection = props => {
  const history = useHistory();

  const handleCtaClick = () => {
    history.push('/s');
  };

  return (
    <div className={css.backdrop}>
      <div className={css.content}>
        <p className={css.titleParagraph}>
          Are you a food influencer? <span>😋📸</span>
        </p>
        <h1 className={css.title}>Why Influencers Love TasteTribe</h1>
        <div className={css.cardContainer}>
          {restaurantsCards.map((card, index) => {
            return (
              <div key={index} className={css.card}>
                <div className={css.cardIcon}>{card.icon}</div>
                <h3 className={css.cardTitle}>{card.title}</h3>
                <p className={css.cardDescription}>{card.description}</p>
              </div>
            );
          })}
        </div>
        <button className={css.primaryButton} onClick={handleCtaClick}>
          Join as an Influencer
        </button>
      </div>
      <img src="static/images/utensils.png" alt="Utensils Image" className={css.utensils} />
    </div>
  );
};

InfluencersSection.defaultProps = {};

InfluencersSection.propTypes = {};

export default InfluencersSection;
