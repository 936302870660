import React from 'react';

import css from './TransformHero.module.css';
import { NamedRedirect, NamedLink } from '../../components';
import { useHistory } from 'react-router-dom';

const TransformHero = props => {
  const history = useHistory();

  const handleCtaClick = () => {
    history.push('/s');
  };

  return (
    <div className={css.backdrop}>
      <div className={css.content}>
        <h1 className={css.title}>Transform Your Food Marketing</h1>
        <p className={css.text}>
          TasteTribe connects restaurants with food influencers to help them grow{' '}
          <br className={css.titleBreak} /> their brand and reach more customers. It’s easy: find an
          influencer, <br className={css.titleBreak} />
          start a campaign, and get more people through your doors.
        </p>
      </div>
      <img src="static/images/speaker.png" alt="Speaker Image" className={css.speaker} />
    </div>
  );
};

TransformHero.defaultProps = {};

TransformHero.propTypes = {};

export default TransformHero;
